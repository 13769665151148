import { createContext, useEffect, useReducer, Dispatch, useCallback } from "react";
import axios from "axios";
import { AppStateAction } from "./actions";
import { Account, AppState, Channel, Oven, Proxy, Search, SearchUpdate } from "./types";
import io from "socket.io-client";

const socket = io("/events", {
    autoConnect: false
});

const defaultState = {
    isLoaded: false,
    loggedIn: false,
    channels: [],
    accounts: [],
    proxies: [],
    ovens: [],
    searches: []
};

export const AppStateContext = createContext<AppState>(defaultState);
export const AppStateDispatch = createContext<Dispatch<AppStateAction>>(() => {});


function rootReducer(state: AppState, action: AppStateAction): AppState {
    switch (action.type) {
        case 'LOADED': {
            return ({
                channels: action.channels,
                ovens: action.ovens,
                accounts: action.accounts,
                proxies: action.proxies,
                searches: action.searches,
                loggedIn: action.loggedIn,
                isLoaded: true, 
            })
        }
        case "SEARCH_ADD": {
            return ({
                ...state,
                searches: [action.search, ...state.searches]
            })
        }
        case "SEARCH_REMOVE": {
            return ({
                ...state,
                searches: state.searches.filter(search => search._id !== action.id)
            })
        }
        case "SEARCHES_UPDATE": {
            const parsed = action.update.reduce((prev, update) => ({...prev, [update._id]: update.stat}), {});
            return ({
                ...state,
                searches: state.searches.map(
                    search => (search._id in parsed) ?
                        {
                            ...search,
                            stat: parsed[search._id as keyof typeof parsed],
                        }
                        :
                        search
                )
            })
        }
        case "PROXY_ADD": {
            return ({
                ...state,
                proxies: [action.proxy, ...state.proxies]
            })
        }
        case 'PROXY_EDIT': {
            return ({
                ...state, 
                proxies: state.proxies.map(proxy => proxy._id === action.id ? action.proxy : proxy)
            })
        }
        case "PROXY_REMOVE": {
            return ({
                ...state,
                proxies: state.proxies.filter(proxy => proxy._id !== action.id),
                accounts: state.accounts.map(account => 
                    account.proxy?._id === action.id ?
                    {
                        ...account,
                        proxy: null
                    }
                    :
                    account
                )
            })
        }
        case "ACCOUNT_ADD": {
            return ({
                ...state,
                accounts: [action.account, ...state.accounts]
            })
        }
        case 'ACCOUNT_EDIT': {
            return ({
                ...state, 
                accounts: state.accounts.map(account => account._id === action.id ? action.account : account)
            })
        }
        case "ACCOUNT_REMOVE": {
            return ({
                ...state,
                accounts: state.accounts.filter(account => account._id !== action.id)
            })
        }
        case 'ACCOUNT_LINK': {
            const account = state.accounts.find(account => account._id === action.account_id) as Account;
            return ({
                ...state,
                channels: state.channels.map(
                    channel => channel._id === action.channel_id ? 
                    ({
                        ...channel, 
                        linked_account: account
                    }) :
                    channel
                )
            });
        }
        case 'ACCOUNT_UNLINK': {
            return ({   
                ...state,
                channels: state.channels.map(
                    channel => channel._id === action.channel_id ? 
                        ({
                            ...channel, 
                            linked_account: null
                        }) : 
                        channel
                )
            });
        }
        case 'CHANNEL_ADD': {
            return ({
                ...state, 
                channels: [action.channel, ...state.channels]
            })
        }
        case 'CHANNEL_EDIT': {
            return ({
                ...state, 
                channels: state.channels.map(channel => channel._id === action.id ? action.channel : channel)
            })
        }
        case 'CHANNEL_REMOVE': {
            const targetChannel = state.channels.find(channel => channel._id === action.id) as Channel;
            return ({
                ...state, 
                channels: state.channels.filter(channel => channel._id !== action.id),
                ovens: state.ovens.map(
                    oven => oven._id === targetChannel.linked_oven?._id ?
                    ({
                        ...oven,
                        linked_channels: oven.linked_channels.filter(
                            (channel: any) => channel !== targetChannel._id
                        )
                    })
                    :
                    oven
                    
                ),
            });
        }
        case 'OVEN_ADD': {
            return ({
                ...state, 
                ovens: [action.oven, ...state.ovens]
            })
        }
        case 'OVEN_EDIT': {
            return ({
                ...state, 
                ovens: state.ovens.map(oven => oven._id === action.id ? action.oven : oven)
            })
        }
        case 'OVEN_REMOVE': {
            return ({
                ...state, 
                ovens: state.ovens.filter(oven => oven._id !== action.id)
            })
        }
        case 'OVEN_LINK': {
            const oven = state.ovens.find(oven => oven._id === action.oven_id) as Oven;
            const channel = state.channels.find(channel => channel._id === action.channel_id) as Channel;
            return ({
                ...state,
                channels: state.channels.map(
                    channel => channel._id === action.channel_id ? 
                    ({
                        ...channel, 
                        linked_oven: oven
                    }) :
                    channel
                ),
                ovens: state.ovens.map(
                    oven => oven._id === action.oven_id ? 
                    ({
                        ...oven, 
                        linked_channels: [channel, ...oven.linked_channels]
                    }) : 
                    ({
                        ...oven,
                        linked_channels: oven.linked_channels.filter(
                            _channel => _channel._id !== channel._id
                        )
                    })
                ),
            });
        }
        case 'OVEN_UNLINK': {
            const channel = state.channels.find(channel => channel._id === action.channel_id) as Channel;
            const targetOven = state.ovens.find(oven => oven._id === channel.linked_oven?._id) as Oven;
            return ({   
                ...state,
                channels: state.channels.map(
                    channel => channel._id === action.channel_id ? 
                        ({
                            ...channel, 
                            linked_oven: null
                        }) : 
                        channel
                ),
                ovens: state.ovens.map(
                    oven => oven._id === targetOven._id ? 
                        ({
                            ...oven, 
                            linked_channels: oven.linked_channels.filter(
                                _channel => _channel._id !== channel._id
                            )
                        }) : 
                        oven
                ),
            });
        }
        default: {
            return state
        }
    }
}

export const AppStateContextProvider: React.FC<{children: any}> = ({children}) => {
    const [state, dispatch] = useReducer(rootReducer, defaultState);

    function checkAuth(): Promise<boolean> {
        return axios.get("/api/auth")
            .then(() => true)
            .catch(() => false)
    }

    const fetchCollection = useCallback(<T extends {_id: string}>(collection: string, propName: string): Promise<T[]> => {
        return axios.get(`/api/${collection}`).then(({data}) => {
            return data[propName]
        }).catch(error => {
            console.error(`error fetching ${collection}s`, error);
            return [];
        })
    }, []);

    const fetchInitData = useCallback( async () => {
        return ({
            channels: await fetchCollection<Channel>("channel", "channels"),
            accounts: await fetchCollection<Account>("account", "accounts"),
            ovens: await fetchCollection<Oven>("oven", "ovens"),
            proxies: await fetchCollection<Proxy>("proxy", "proxies"),
            searches: await fetchCollection<Search>("search", "searches"),
            loggedIn: await checkAuth(),
        })
    }, [])

    const handleUpdateSearches = useCallback((update: SearchUpdate[]) => {
        dispatch({
            type: "SEARCHES_UPDATE",
            update: update
        })
    }, [])

    useEffect(() => {
        fetchInitData().then(data => {
            dispatch({
                type: "LOADED",
                ...data
            })
        });
        socket.connect();
        socket.on("UPDATE_SEARCHES", handleUpdateSearches);
        return () => {
            socket.removeAllListeners();
            socket.disconnect();
        }
    }, []);

    return (
        <AppStateDispatch.Provider value={dispatch}>
            <AppStateContext.Provider value={state}>
                {children}
            </AppStateContext.Provider>
        </AppStateDispatch.Provider>
    )
}