import { useState, useEffect, useContext, useCallback } from 'react';
import io from "socket.io-client";
import { Button, Input, Select, Space, message, Modal, Popconfirm, Switch } from 'antd';
import { AppStateContext, AppStateDispatch } from '../context/AppStateContext';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import React from 'react';
import { Account } from '../context/types';
import ReactCountryFlag from 'react-country-flag';
import { parsePhoneNumber } from "awesome-phonenumber";
import axios from 'axios';
import TDataUploader from '../components/TdataUploader';
import ProxySelector from '../components/ProxySelector';

const socket = io("/auth", {
  autoConnect: false
});

function formatPhoneNumber(phone: string){
    const parsed = parsePhoneNumber(`+${phone}`);
    return parsed.number?.international
}


const AccountItem: React.FC<{account: Account}> = ({account}) => {
    const dispatch = useContext(AppStateDispatch);
    const [deleleLoading, setDeleteLoading] = useState(false);
    const [toggleLoading, setToggleLoading] = useState(false);

    function toggleOnline(online: boolean){
        setToggleLoading(true);
        axios.put(`/api/account/${account._id}/online`, { online }).then(({data}) => {
            dispatch({
                type: "ACCOUNT_EDIT",
                id: account._id,
                account: data
            })
            message.success(online ? "Online refresh turned on" : "Online refresh turned off");
        }).catch(error => {
            console.log(error);
            message.error("An error occured!");
        }).finally(() => {
            setToggleLoading(false);
        })
    }
    
    const deleteAccount = useCallback((id: string) => {
        setDeleteLoading(true);
        axios({
            url: `/api/account/${id}`,
            method: "delete"
        }).then(({data}) => {
            message.success("Account deleted successfully")
            dispatch({
                type: "ACCOUNT_REMOVE",
                id: data._id
            })
        }).catch(err => {
            message.error("Error deleting account")
        }).finally(() => {
            setDeleteLoading(false);
        })
    }, []);

    return (
        <div className="account-item">
            <div className="header">
                <div
                    className="icon-wrapper"
                    style={{backgroundColor: account.status ? "#349f2c" : "#4c4d51"}}
                >
                    <img src="/icon/user-light.svg" alt="" className="icon" />
                </div>
                <div className="details">
                    <div className="title">
                        <ReactCountryFlag className="flag" countryCode={account.country} />
                        <span>{formatPhoneNumber(account.phone)}</span>
                    </div>
                    <div className="proxy">
                        {account.proxy ? account.proxy.host : "no proxy"}
                    </div>
                </div>
                <div className="switch-wrapper">
                    <Switch
                        loading={toggleLoading}
                        size="small"
                        checked={account.online}
                        onChange={toggleOnline}
                    />
                </div>
                <div className="actions">
                    <Popconfirm
                        disabled={deleleLoading}
                        title="Really wanna delete this account?"
                        onConfirm={() => deleteAccount(account._id)}
                        okType="danger"
                        okText="Fuck yea"
                        cancelText="Hell no"
                    >
                        <button
                            className="action-button">
                            <img
                                className="icon"
                                src="icon/trash-can.svg" 
                                alt="" 
                            />
                        </button>
                    </Popconfirm>
                </div>
            </div>
        </div>
    )
}

const AddAccount: React.FC = () => {
    const dispatch = useContext(AppStateDispatch);
    const { proxies } = useContext(AppStateContext); 
    const [phone, setPhone] = useState<string>("");
    const [proxy, setProxy] = useState("");
    const [processing, setProcessing] = useState<boolean>(false);
    const [connected, setConnected] = useState(false);
    const [step, setStep] = useState<0 | 1 | 2>(0);
    const [authLoading, setAuthLoading] = useState(false);
    const [code, setCode] = useState("");
    const [pass, setPass] = useState("");

    function handleError(event: {err: string}){
        switch(event.err){
            case "PHONE_NUMBER_INVALID":
                setProcessing(false);
                message.error("Phone number invalid");
                break;
            case "FLOOD":
                message.error("Flood detected. Stop doing shit pls")
                break;
            case "PHONE_CODE_INVALID":
                message.error("SMS code is invalid");
                break;
            case "PASSWORD_HASH_INVALID":
                message.error("Password is invalid");
                break;
            case "PHONE_EXISTS":
                setProcessing(false);
                message.error("This phone already exists");
                break;
            default:
                message.error(`Error: ${event.err}`);
                break;
        }
    }
    
    useEffect(() => {
        socket.connect();
        socket.on("connect", () => setConnected(true));
        socket.on("auth-err", handleError);
        socket.on("code-request", handleCodeRequest);
        socket.on("pass-request", handlePassRequest);
        socket.on("success", handleSuccess);
        return () => {
            socket.removeAllListeners();
            socket.disconnect();
        }
    }, []);

    const startAuth = useCallback((phone: string, proxy: string) => {
        setProcessing(true);
        socket.emit("auth", { phone, proxy });
    }, []);

    const handleSuccess = useCallback((event: {account: Account}) => {
        setAuthLoading(false);
        setProcessing(false);
        setStep(0);
        setCode("");
        setPass("");
        setPhone("");
        message.success("Account added successfuly");
        dispatch({
            type: "ACCOUNT_ADD",
            account: event.account
        })
    }, [])

    const handlePassRequest = useCallback(() => {
        message.info("Provide password, pls")
        setAuthLoading(false);
        setStep(2);
    }, []);

    const handleCodeRequest = useCallback(() => {
        setAuthLoading(false);
        setStep(1);
    }, []);

    const provideCode = useCallback((code: string) => {
        setAuthLoading(true);
        socket.emit("code", { phone, code });
    }, [phone]);

    const providePass = useCallback((pass: string) => {
        setAuthLoading(true);
        socket.emit("pass", { phone, pass });
    }, [phone]);

    const abortAuth = useCallback(() => {
        setStep(0);
        setCode("");
        setPass("");
        setProcessing(false);
        socket.emit("abort", { phone });
    }, [phone])

    return (
        <>
        <Space.Compact block size="middle">
            <Input
                disabled={processing}
                autoComplete="off"
                allowClear
                placeholder="44 6000 708090"
                prefix="+"
                onChange={e => setPhone(e.target.value.replaceAll(" ", ""))}
                value={phone}
            />
            <ProxySelector
                proxies={proxies}
                disabled={processing}
                value={proxy}
                onChange={setProxy}
            />
            <Button
                onClick={() => startAuth(phone, proxy)}
                loading={processing || !connected}
                children="Start auth"
            />
            <TDataUploader
                proxy={proxy}
            />
        </Space.Compact>
        <Modal
            open={step !== 0}
            title={`Auth for +${phone}`}
            footer={null}
            onCancel={abortAuth}
        >
            <Space direction="vertical" style={{width: "100%"}}>
                <Input
                    disabled={step === 2}
                    onPressEnter={() => step === 1 ? provideCode(code) : providePass(pass)}
                    placeholder="Auth code"
                    value={code}
                    onChange={e => setCode(e.target.value)}
                />
                <Input
                    disabled={step === 1}
                    onPressEnter={() => step === 1 ? provideCode(code) : providePass(pass)}
                    placeholder="2FA password"
                    autoCorrect="false"
                    autoComplete="false"
                    value={pass}
                    onChange={e => setPass(e.target.value)}
                />
                <div className="auth-modal-buttons-wrapper">
                    <Button
                        className="auth-modal-button-abort"
                        danger
                        children="Abort auth"
                        onClick={abortAuth}
                    />
                    <Button
                        className="auth-modal-button-confirm"
                        loading={authLoading}
                        children={step === 1 ? "Provide code" : "Provide password"}
                        onClick={() => step === 1 ? provideCode(code) : providePass(pass)}
                    />
                </div>
            </Space>
        </Modal>
        </>
    )
}

const AccountList: React.FC = () => {
    const {isLoaded, accounts} = useContext(AppStateContext);

    return isLoaded ?
        <div className="accounts-list">
            {
                accounts.map(account => (
                    <AccountItem 
                        key={account._id} 
                        account={account} 
                    />
                ))
            }
        </div>
        :
        <div className='list-loading'>
            <LoadingPlaceholder size={64} color="#20293a" />
        </div>   
}

const AccountsPage: React.FC<{}> = () => {
    return (
        <>
            <h2 className="page-title">Accounts</h2>
            <AddAccount />
            <AccountList />
        </>
    )
}

export default AccountsPage;