import { InboxOutlined } from "@ant-design/icons";
import { Button, Modal, Upload, message } from "antd";
import { useContext, useState } from "react";
import { AppStateDispatch } from "../context/AppStateContext";

const UploaderModal: React.FC<{
    proxy: string
}> = ({proxy}) => {
    const dispatch = useContext(AppStateDispatch);
    return (
        <div>
            <Upload.Dragger
                name="tdata"
                multiple={false}
                accept=".zip"
                data={{
                    proxy: proxy
                }}
                action="/api/account/tdata"
                style={{
                    margin: "10px 0",
                    padding: 20
                }}
                onChange={(info) => {
                    const { status, response } = info.file;
                    if (status === 'done') {
                        dispatch({
                            type: "ACCOUNT_ADD",
                            account: response
                        });
                        
                        message.success(`Imported successfully`);
                    } else if (status === 'error') {
                        console.error(response)
                        message.error(`Import failed`);
                    }
                }}
            >
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag tdata.zip to this area to import</p>
                <p className="ant-upload-hint">Использование этой функции имет <b>ненулевые шансы</b> сломать всё нахуй</p>
            </Upload.Dragger>
        </div>
    )
}


const TDataUploader: React.FC<{
    proxy: string
}> = ({proxy}) => {
    const [modalOpen, setModalOpen] = useState(false);

    const handleClose = () => {
        setModalOpen(false);
    }

    const handleOpen = () => {
        setModalOpen(true);
    }

    return (
        <>
            <Button
                onClick={handleOpen}
                children="Import"
            />
            <Modal
                title="Import account"
                onCancel={handleClose}
                cancelText="Close"
                okButtonProps={{style: {
                    display: "none"
                }}}
                open={modalOpen}
                children={
                    <UploaderModal
                        proxy={proxy}
                    />
                }
            />
        </>
    )
}

export default TDataUploader;