import { FileImageOutlined } from "@ant-design/icons";
import { message, Button } from "antd";
import axios from "axios";
import { useContext, useState, useCallback } from "react";
import { AppStateDispatch } from "../context/AppStateContext";
import { Channel } from "../context/types";

const DeleteWarmupMedia: React.FC<{id: string}> = ({id}) => {
    const dispatch = useContext(AppStateDispatch);
    const [loading, setLoading] = useState(false);

    const deleteWarmupMedia = useCallback((id: string) => {
        setLoading(true);
        axios<Channel>({
            method: "delete",
            url: `/api/channel/${id}/warmup-media`
        }).then(({data}) => {
            dispatch({
                type: "CHANNEL_EDIT",
                id: id,
                channel: data
            });
            message.success("Warmup media deleted")
        }).catch((e) => {
            console.error(e);
            message.error("Error deleting warmup media")
        }).finally(() => {
            setLoading(false);
        })
    }, [])

    return (
        <Button
            danger
            onClick={() => deleteWarmupMedia(id)}
            loading={loading}
            icon={<FileImageOutlined />}
            children="Delete warmup media"
        />
    )
}

export default DeleteWarmupMedia;