import ReactDOM from 'react-dom/client';
import Router from './pages';
import { BrowserRouter } from 'react-router-dom';
import { AppStateContextProvider } from './context/AppStateContext';
import "./styles/index.sass"
import "antd/dist/reset.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <AppStateContextProvider>
        <Router />
      </AppStateContextProvider>
    </BrowserRouter>
  // </React.StrictMode>
);
