import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import React, { useContext, useLayoutEffect } from "react";
import PageLayout from "../containers/PageLayout"

import Channels from "./Channels";
import Channel from "./Channel";
import Accounts from "./Accounts";
import Proxies from "./Proxies";
import Searches from "./Searches";
import Ovens from "./Ovens";
import Login from "./Login";
import NotFound from "./NotFound";
import { AppStateContext } from "../context/AppStateContext";
import LoadingPlaceholder from "../components/LoadingPlaceholder";

const ScrollToTop: React.FC<{children: JSX.Element}> = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
}

const Router: React.FC<{}> = () => {
    const {isLoaded, loggedIn} = useContext(AppStateContext);
    
    if(!isLoaded) return (
        <div className='list-loading'>
            <LoadingPlaceholder size={64} color="#20293a" />
        </div>
    )

    if(!loggedIn) return <Login />

    return (
        <ScrollToTop>
            <Routes>
                <Route
                    path="/" 
                    element={ <Navigate to="/channels" replace /> }
                />
                <Route
                    element={<PageLayout />}
                >
                    <Route
                        path="/channels"
                        element={<Channels />}
                    />
                    <Route
                        path="/channel/:id"
                        element={<Channel />}
                    />
                    <Route
                        path="/ovens"
                        element={<Ovens />}
                    />
                    <Route
                        path="/accounts"
                        element={<Accounts />}
                    />
                    <Route
                        path="/proxies"
                        element={<Proxies />}
                    />
                    <Route
                        path="/searches"
                        element={<Searches />}
                    />
                    <Route
                        path="*"
                        element={<NotFound />}
                    />
                </Route>
            </Routes>
        </ScrollToTop>
    )
}

export default Router;