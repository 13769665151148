import { Button, Input, InputNumber, Popconfirm, Space, message } from "antd";
import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { AppStateContext, AppStateDispatch } from "../context/AppStateContext";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
import {Proxy} from "../context/types";
import ReactCountryFlag from "react-country-flag";

const ERROR: {[key: string]: string} = {
    "PROXY_EXISTS": "Proxy with this host already exists"
}

const AddProxy: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useContext(AppStateDispatch);

    const [host, setHost] = useState<string>("");
    const [port, setPort] = useState<number | null>(null);
    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    function clearForm(){
        setHost("");
        setPort(null);
        setLogin("");
        setPassword("");
    }
    
    function addProxy(){
        setLoading(true);
        axios.post("/api/proxy", {host, port, login, password}).then(({data}) => {
            message.success("Proxy created succesfully");
            clearForm();
            dispatch({
                type: "PROXY_ADD",
                proxy: data
            });
        }).catch(error => {
            message.error(ERROR[error.response.data.message] || "Error creating proxy")
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Space.Compact block size="middle">
            <Input
                placeholder="host"
                autoCorrect="off"
                value={host}
                onChange={e => setHost(e.target.value)}
            />
            <InputNumber
                placeholder="port"
                style={{
                    width: "100%"
                }}
                min={0}
                controls={false}
                keyboard={false}
                value={port}
                onChange={setPort}
            />
            <Input
                placeholder="login"
                autoCorrect="off"
                value={login}
                onChange={e => setLogin(e.target.value)}
            />
            <Input
                placeholder="password"
                autoCorrect="off"
                value={password}
                onChange={e => setPassword(e.target.value)}
            />
            <Button
                loading={loading}
                onClick={addProxy}
                children="Add proxy"
            />
        </Space.Compact>
    )
}

const ProxyItem: React.FC<{proxy: Proxy}> = ({proxy}) => {
    const [loading, setLoading] = useState(false);

    const dispatch = useContext(AppStateDispatch);

    const deleteProxy = useCallback((id: string) => {
        setLoading(true);
        axios.delete(`/api/proxy/${id}`).then(() => {
            message.success(`Proxy deleted sucessfully`)
            dispatch({
                type: "PROXY_REMOVE",
                id: id
            })
        }).catch(error => {
            console.error(error)
            message.error("Error deleting proxy")
        }).finally(() => {
            setLoading(false)
        })
    }, []);

    const refreshProxy = useCallback((id: string) => {
        setLoading(true);
        axios.put(`/api/proxy/${id}`).then(({data}) => {
            message.success(`Proxy refreshed sucessfully`)
            dispatch({
                type: "PROXY_EDIT",
                id: id,
                proxy: data
            })
        }).catch(error => {
            console.error(error)
            message.error("Error refreshing proxy")
        }).finally(() => {
            setLoading(false)
        })
    }, []);

    return (
        <div className="proxy-item">
            <div className="header">
                <div
                    className="icon-wrapper"
                    style={{backgroundColor: proxy.status ? "#2e86de" : "#4c4d51"}}
                >
                    <img src="/icon/globe-solid.svg" alt="" className="icon" />
                </div>
                <div className="details">
                    <div className="title">
                        <ReactCountryFlag className="flag" countryCode={proxy.country} />
                        <span>{proxy.host}</span>
                    </div>
                </div>
                <div className="actions">
                    <button
                        onClick={() => refreshProxy(proxy._id)}
                        disabled={loading}
                        className="action-button">
                        <img
                            className="icon"
                            src="icon/repeat.svg" 
                            alt="" 
                        />
                    </button>
                    <Popconfirm
                        disabled={loading}
                        title="Really wanna delete this proxy?"
                        onConfirm={() => deleteProxy(proxy._id)}
                        okType="danger"
                        okText="Fuck yea"
                        cancelText="Hell no"
                    >
                        <button
                            className="action-button">
                            <img
                                className="icon"
                                src="icon/trash-can.svg" 
                                alt="" 
                            />
                        </button>
                    </Popconfirm>
                    <div className="indicator-wrapper">
                        <div
                            className="indicator"
                            style={{
                                backgroundColor: proxy.status ? "#52c41a" : "#ff4d4f"
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProxyList: React.FC = () => {
    const {isLoaded, proxies} = useContext(AppStateContext);
    return isLoaded ?
        <div className="proxies-list">
            {
                proxies.map(proxy => 
                    <ProxyItem
                        key={proxy._id} 
                        proxy={proxy}  
                    />
                )
                    
            }
        </div>
        :
        <div className='list-loading'>
            <LoadingPlaceholder size={64} color="#20293a" />
        </div>
}

const ProxiesPage: React.FC<{}> = () => {
    return (
        <>
            <h2 className="page-title">Proxies</h2>
            <AddProxy />
            <ProxyList />
        </>
    )
}

export default ProxiesPage;