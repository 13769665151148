import { Form, Button, Input } from "antd";
import axios from "axios";

const LoginPage: React.FC = () => {

    function onFinish(form: {username: string, password: string}){
        axios.post("/api/auth", form)
            .then(() => {
                window.location.reload();
            })
    }

    return (
        <div className="admin-login-wrapper">
            <div className="admin-login">
            <Form
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
            >
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" style={{width: "100%"}}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            </div>
        </div>
    )
}

export default LoginPage;