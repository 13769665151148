import { Navigate, useParams } from "react-router"
import { AppStateContext, AppStateDispatch } from "../context/AppStateContext";
import { useContext, useState } from "react";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space, message } from "antd";
import axios from "axios";
import { Channel } from "../context/types";
import { OvenSelector } from "../components/OvenSelector";
import { AccountSelector } from "../components/AccountSelector";
import DeleteChannelButton from "../components/DeleteChannelButton";
import SyncChannelButton from "../components/SyncChannelButton";
import ChannelProtectionSwitch from "../components/ChannelProtectionSwitch";
import ChannelRotationSwitch from "../components/ChannelRotationSwitch";
import DeleteWarmupMedia from "../components/DeleteWarmupMedia";
import SetWarmupMedia from "../components/SetWarmupMedia";
import { Link } from "react-router-dom";


const ChannelPage: React.FC<{}> = () => {
    const { channels } = useContext(AppStateContext);
    const dispatch = useContext(AppStateDispatch);
    const [updateLoading, setUpdateLoading] = useState(false);

    const [titleUpdated, setTitleUpdated] = useState(false);
    const [descriptionUpdated, setDescriptionUpdated] = useState(false);

    const { id } = useParams();
    
    const targetChannel = channels.find(channel => channel._id === id);

    function onValuesChange(_: any, changed: Channel){
        setTitleUpdated(changed.info.title !== targetChannel?.info.title);
        setDescriptionUpdated(changed.info.description !== targetChannel?.info.description);
    }

    function updateChannel(channel: Channel){
        setUpdateLoading(true);
        axios.patch(`/api/channel/${id}`, {
            warmup_message: channel.warmup_message,
            title: channel.info.title,
            description: channel.info.description
        }).then(({data}) => {
            message.success("Channel updated")
            setTitleUpdated(false);
            setDescriptionUpdated(false);
            dispatch({
                type: "CHANNEL_EDIT",
                id: id || "",
                channel: data
            })
        }).catch((error) => {
            console.error(error);
            message.error("Error occured")
        }).finally(() => {
            setUpdateLoading(false);
        })
    }
    
    return targetChannel ?
        <>
            <h2 className="page-title breadcrumb">
                <Link className="parent" to="/channels">Channels</Link>
                <img className="separator" src="/icon/angle-right.svg" />
                <span className="current">{targetChannel?.info.username ? `@${targetChannel?.info.username}` : `private ${targetChannel?.chat_id}`}</span>
            </h2>
            <div className="page-columns">
                <div className="block channel-warmup-block">
                    <OvenSelector
                        linked_oven={targetChannel.linked_oven?._id}
                        channel_id={targetChannel._id}
                    />
                    <AccountSelector
                        linked_account={targetChannel.linked_account?._id}
                        channel_id={targetChannel._id}
                    />
                    <Form
                        layout="vertical"
                        autoComplete="off"
                        onValuesChange={onValuesChange}
                        onFinish={updateChannel}
                        initialValues={targetChannel}
                    >
                        <Form.Item
                            name={["info", "title"]}
                            children={
                                <Input
                                    placeholder="Channel title"
                                    autoCorrect="off"
                                    autoComplete="off"
                                    status={titleUpdated ? "warning" : ""}
                                    suffix={titleUpdated ? <EditOutlined /> : <></>}
                                />
                            }
                        />
                        <Form.Item
                            name={["info", "description"]}
                            children={
                                <Input.TextArea
                                    placeholder="Channel description"
                                    autoCorrect="off"
                                    autoComplete="off"
                                    status={descriptionUpdated ? "warning" : ""}
                                    style={{
                                        resize: "none", 
                                        height: "76px"
                                    }}
                                />
                            }
                        />
                        <Form.Item
                            name="warmup_message"
                            children={
                                <Input.TextArea
                                    placeholder="Warmup message"
                                    autoCorrect="off"
                                    autoComplete="off"
                                    style={{
                                        resize: "none", 
                                        height: "200px"
                                    }} 
                                />
                            }
                        />
                        <Space direction="vertical">
                            <ChannelProtectionSwitch
                                id={targetChannel._id}
                                isProtected={targetChannel.protected}
                            />
                            <ChannelRotationSwitch
                                id={targetChannel._id}
                                isRotationEnabled={targetChannel.rotation_enabled}
                            />
                        </Space>
                        <Space
                            style={{
                                marginBottom: 0,
                                marginTop: 20,
                                width: "100%",
                                justifyContent: "flex-end"
                            }}
                        >
                            {targetChannel.warmup_media ?
                                <DeleteWarmupMedia
                                    id={targetChannel._id}
                                />
                                :
                                <SetWarmupMedia
                                    id={targetChannel._id}
                                />
                            }
                            <SyncChannelButton
                                id={targetChannel._id} 
                            />
                            <DeleteChannelButton
                                id={targetChannel._id}
                            />
                            <Button
                                loading={updateLoading}
                                type="primary"
                                icon={<SaveOutlined/>}
                                htmlType="submit"
                                children="Save"
                            />
                        </Space>
                    </Form>
                </div>
            </div>
        </>
        :
        <Navigate to="/404" replace />
}

export default ChannelPage;