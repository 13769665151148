import { RetweetOutlined, SafetyOutlined } from "@ant-design/icons";
import { message, Popconfirm, Space, Switch } from "antd";
import axios from "axios";
import { useContext, useState, useCallback } from "react";
import { AppStateDispatch } from "../context/AppStateContext";
import { Channel } from "../context/types";

const ChannelRotationSwitch: React.FC<{id: string, isRotationEnabled: boolean}> = ({id, isRotationEnabled}) => {
    const dispatch = useContext(AppStateDispatch);
    const [loading, setLoading] = useState(false);

    function getErrorMessage(code: string){
        switch (code) {
            case "NO_LINKED_OVEN":
                return "Error: No linked oven";
            case "NO_LINKED_ACCOUNT":
                return "Error: No linked account";
            case "ACCOUNT_INACTIVE":
                return "Error: Linked account got banned";
            case "ACCOUNT_NOT_CREATOR":
                return "Error: Account is not an owner";
            case "ALREADY_DISABLED":
                return "Error: Already disabled";
            case "ALREADY_ENABLED":
                return "Error: Already enabled";
            case "NOT_ENOUGH_USERNAMES":
                return "Error: Not enough Fragment usernames";
            default:
                return "Error: Shit happened, check logs";
        }
    }

    const enableRotation = useCallback((id: string) => {
        setLoading(true)
        axios<Channel>({
            method: "put",
            url: `/api/channel/${id}/enable-rotation`
        }).then(({data}) => {
            dispatch({
                type: "CHANNEL_EDIT",
                id: data._id,
                channel: data
            })
            message.success("Rotation enabled");
        }).catch(error => {
            console.error(error);
            message.error(getErrorMessage(error.response.data.message));
        }).finally(() => {
            setLoading(false);
        });
    }, [dispatch])

    const disableRotation = useCallback((id: string) => {
        console.log(123)
        setLoading(true)
        axios<Channel>({
            method: "put",
            url: `/api/channel/${id}/disable-rotation`
        }).then(({data}) => {
            dispatch({
                type: "CHANNEL_EDIT",
                id: data._id,
                channel: data
            })
            message.warning("Protection deactivated");
        }).catch(error => {
            console.error(error);
            message.error("Unable to deactivate protection");
        }).finally(() => {
            setLoading(false);
        });
    }, [dispatch])

    return (
        <Space align="center" style={{
            marginBottom: "15px"
        }}>
            <Popconfirm
                title={isRotationEnabled ?
                    "Random Fragment username will be activated instead of Rotation"
                    :
                    "This will deactivate the basic username and activate Rotation"
                }
                onConfirm={() => isRotationEnabled ? disableRotation(id) : enableRotation(id)}
            >
                <Switch
                    loading={loading}
                    checked={isRotationEnabled}
                    checkedChildren={<RetweetOutlined />}
                    defaultChecked
                />
            </Popconfirm>
            <span className="switch-label">Username rotation</span>
        </Space>
    )
}

export default ChannelRotationSwitch;