import { UploadOutlined } from "@ant-design/icons";
import { Upload, message, Button } from "antd";
import { useContext } from "react";
import { AppStateDispatch } from "../context/AppStateContext";

const SetWarmupMedia: React.FC<{id: string}> = ({id}) => {
    const dispatch = useContext(AppStateDispatch);

    return (
        <Upload
            name="warmup_media"
            multiple={false}
            showUploadList={false}
            accept=".png,.jpg,.jpeg"
            action={`/api/channel/${id}/warmup-media`}
            onChange={(info) => {
                const { status, response } = info.file;
                if (status === 'done') {
                    dispatch({
                        type: "CHANNEL_EDIT",
                        id: response._id,
                        channel: response
                    });
                    message.success(`Media uploaded successfully`);
                } else if (status === 'error') {
                    console.error(response)
                    message.error(`Media upload failed`);
                }
            }}
        >
            <Button icon={<UploadOutlined />}>Upload warmup media</Button>
        </Upload>
    )
}

export default SetWarmupMedia;