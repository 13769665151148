import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { useEffect, useState } from 'react';

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("en-US");

export function useForceUpdate(){
    const [, setValue] = useState(0);
    return () => setValue(value => value + 1); 
}

export const DynamicTime: React.FC<{timestamp: number}> = ({timestamp}) => {
    const forceUpdate = useForceUpdate();

    useEffect(() => {
        const i = setInterval(forceUpdate, 30 * 1000);
        return () => {
            clearInterval(i);
        }
    }, [forceUpdate]);

    return (
        <span>{timeAgo.format(timestamp)}</span>
    )
}