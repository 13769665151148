import { SafetyOutlined } from "@ant-design/icons";
import { message, Popconfirm, Space, Switch } from "antd";
import axios from "axios";
import { useContext, useState, useCallback } from "react";
import { AppStateDispatch } from "../context/AppStateContext";
import { Channel } from "../context/types";

const ChannelProtectionSwitch: React.FC<{id: string, isProtected: boolean}> = ({id, isProtected}) => {
    const dispatch = useContext(AppStateDispatch);
    const [loading, setLoading] = useState(false);

    function getErrorMessage(code: string){
        switch (code) {
            case "DISABLE_ROTATION_FIRST":
                return "Error: Disable Rotation first";
            case "NO_LINKED_OVEN":
                return "Error: No linked oven";
            case "NO_LINKED_ACCOUNT":
                return "Error: No linked account";
            case "ACCOUNT_INACTIVE":
                return "Error: Linked account got banned";
            case "STATS_UNAVAILABLE":
                return "Error: Stats unavailable";
            default:
                return "Error: Shit happened, check logs";
        }
    }

    const activateProtection = useCallback((id: string) => {
        setLoading(true)
        axios<Channel>({
            method: "put",
            url: `/api/channel/${id}/activate-protection`
        }).then(({data}) => {
            dispatch({
                type: "CHANNEL_EDIT",
                id: data._id,
                channel: data
            })
            message.success("Protection activated");
        }).catch(error => {
            console.error(error);
            message.error(getErrorMessage(error.response.data.message));
        }).finally(() => {
            setLoading(false);
        });
    }, [dispatch])

    const deactivateProtection = useCallback((id: string) => {
        console.log(123)
        setLoading(true)
        axios<Channel>({
            method: "put",
            url: `/api/channel/${id}/deactivate-protection`
        }).then(({data}) => {
            dispatch({
                type: "CHANNEL_EDIT",
                id: data._id,
                channel: data
            })
            message.warning("Protection deactivated");
        }).catch(error => {
            console.error(error);
            message.error("Unable to deactivate protection");
        }).finally(() => {
            setLoading(false);
        });
    }, [dispatch])

    return (
        <Space align="center" style={{
            marginBottom: "15px"
        }}>
            <Popconfirm
                title={isProtected ? 
                    "Are you sure want to turn protection off?" 
                    :
                    "This action will delete all the messages!"
                }
                onConfirm={() => isProtected ? deactivateProtection(id) : activateProtection(id)}
            >
                <Switch
                    loading={loading}
                    checked={isProtected}
                    checkedChildren={<SafetyOutlined />}
                    defaultChecked
                />
            </Popconfirm>
            <span className="switch-label">Channel protection</span>
        </Space>
    )
}

export default ChannelProtectionSwitch;