import { Select, message } from "antd";
import { Oven } from "../context/types";
import axios from "axios";
import { useState, useContext } from "react";
import { AppStateDispatch, AppStateContext } from "../context/AppStateContext";

function getOvenOptions(ovens: Oven[]){
    const no_oven = ({
        value: "",
        label: "--- No oven ---",
        size: 0,
        occupied: 0
    });
    const options = ovens.map(oven => ({
        value: oven._id,
        label: oven.username,
        size: oven.size,
        occupied: oven.linked_channels.length
    }));
    return [no_oven, ...options];
}

export const OvenSelector: React.FC<{channel_id: string, linked_oven: string | undefined}> = ({channel_id, linked_oven}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useContext(AppStateDispatch);
    const { ovens } = useContext(AppStateContext);

    function getErrorMessage(code: string){
        switch (code) {
            case "PROTECTION_ENABLED":
                return "Error: Disable protection first";
            default:
                return "Error: Shit happened, check logs";
        }
    }

    function linkOven(id: string){
        axios.put(`/api/channel/${channel_id}/link-oven`, { id }).then(({data}) => {
            console.log(data);
            message.success(`Channel linked sucessfuly`)
            dispatch({
                type: "OVEN_LINK",
                channel_id: channel_id,
                oven_id: id
            })
        }).catch(error => {
            console.log(error)
            message.error(getErrorMessage(error.response.data.message));
        }).finally(() => {
            setLoading(false);
        })
    }

    function unlinkOven(){
        axios.put(`/api/channel/${channel_id}/unlink-oven`).then(({data}) => {
            message.success(`Channel unlinked sucessfuly`)
            dispatch({
                type: "OVEN_UNLINK",
                channel_id: channel_id,
            })
        }).catch(error => {
            console.log(error)
            message.error(getErrorMessage(error.response.data.message));
        }).finally(() => {
            setLoading(false);
        })
    }

    function onOvenSelect(id: string){
        setLoading(true);
        id === "" ? unlinkOven() : linkOven(id);
    }

    return (
        <Select
            className="selector"
            style={{width: "100%"}}
            loading={loading}
            disabled={loading}
            value={linked_oven || ""}
            onChange={onOvenSelect}
            children={getOvenOptions(ovens).map(option => (
                <Select.Option
                    key={`${message}${option.value}`}
                    disabled={option.size === option.occupied && option.value !== ""} 
                    value={option.value}
                >
                    <div className="oven-option">
                        <span className="option-label">{option.label}</span>
                        {
                            option.size ? <span className="size">{option.occupied} / {option.size}</span> : <></>
                        }
                    </div>
                </Select.Option>
            ))}
        />
    )
}