

const NotFoundPage: React.FC = () => {
    return (
        <div className="not-found-page">
            <h4 className="not-found-label">404</h4>
        </div>
    )
}

export default NotFoundPage;