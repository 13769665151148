import { Outlet } from "react-router";
import { Page } from "./Page";
import { Header } from "../components/Header";
import { PageContent } from "./PageContent";

const PageLayout: React.FC<{}> = () => {
    return (
        <Page>
            <Header />
            <PageContent>
                <Outlet />
            </PageContent>
        </Page>
    )
}

export default PageLayout;