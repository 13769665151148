

const Avatar: React.FC<{src: string | null, title: string, size?: "small" | "medium" | "large", onClick?: () => void}> = ({src, title, size = "", onClick}) => {
    return (
        <div
            className={`avatar-wrapper ${size}`}
            onClick={onClick}
        >
            {
                src ?
                    <img src={src} alt={title} className="avatar" /> 
                    :
                    <div className="letter">{title.slice(0,1).toLocaleUpperCase()}</div>
            }
        </div>
    )
}

export default Avatar;