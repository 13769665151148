import { LoadingOutlined } from "@ant-design/icons"


const LoadingPlaceholder: React.FC<{size: number, color?: string}> = ({size, color}) => {
    return (
        <div className="loading-placeholder">
            <LoadingOutlined 
                style={{
                    fontSize: `${size}px`,
                    color
                }} 
            />
        </div>
    )
}

export default LoadingPlaceholder;