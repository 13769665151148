import { message, Select } from "antd";
import axios from "axios";
import { useState, useContext } from "react";
import ReactCountryFlag from "react-country-flag";
import { AppStateDispatch, AppStateContext } from "../context/AppStateContext";
import { Account } from "../context/types";

function getAccountOptions(accounts: Account[]){
    const no_account = ({
        value: "",
        label: "--- No account ---",
        country: null
    });
    const options = accounts.map(account => ({
        value: account._id,
        label: account.phone,
        country: account.country,
    }));
    return [no_account, ...options];
}

export const AccountSelector: React.FC<{channel_id: string, linked_account: string | undefined}> = ({channel_id, linked_account}) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useContext(AppStateDispatch);
    const { accounts } = useContext(AppStateContext);

    function getErrorMessage(code: string){
        switch (code) {
            case "PROTECTION_ENABLED":
                return "Error: Disable protection first";
            default:
                return `Error: ${code}`;
        }
    }

    function linkAccount(id: string){
        axios.put(`/api/channel/${channel_id}/link-account`, { id }).then(({data}) => {
            console.log(data);
            message.success(`Account linked sucessfuly`)
            dispatch({
                type: "ACCOUNT_LINK",
                channel_id: channel_id,
                account_id: id
            })
        }).catch(error => {
            message.error(
                getErrorMessage(error.response.data.message)
            );
        }).finally(() => {
            setLoading(false);
        })
    }

    function unlinkAccount(){
        axios.put(`/api/channel/${channel_id}/unlink-account`).then(() => {
            message.success(`Account unlinked sucessfuly`)
            dispatch({
                type: "ACCOUNT_UNLINK",
                channel_id: channel_id,
            })
        }).catch(error => {
            message.error(
                getErrorMessage(error.response.data.message)
            );
        }).finally(() => {
            setLoading(false);
        })
    }

    function onAccountSelect(id: string){
        setLoading(true);
        id === "" ? unlinkAccount() : linkAccount(id);
    }

    return (
        <Select
            className="selector"
            style={{width: "100%"}}
            loading={loading}
            disabled={loading}
            value={linked_account || ""}
            onChange={onAccountSelect}
            children={getAccountOptions(accounts).map(option => (
                <Select.Option
                    key={`${message}${option.value}`}
                    value={option.value}
                >
                    <div className="account-option">
                        {option.country !== null ? <ReactCountryFlag className="flag" countryCode={option.country} /> : <></>}
                        <span className="option-label">{option.label}</span>
                    </div>
                </Select.Option>
            ))}
        />
    )
}