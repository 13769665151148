import { Button, message } from "antd"
import axios from "axios";
import { useContext, useState } from "react";
import { AppStateDispatch } from "../context/AppStateContext";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";


const DeleteChannelButton: React.FC<{id: string}> = ({id}) => {
    const dispatch = useContext(AppStateDispatch);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const deleteChannel = (id: string) => {
        setLoading(true);
        axios({
            method: "delete",
            url: `/api/channel/${id}`
        }).then(({data}) => {
            message.success("Channel deleted");
            navigate("/channels")
            dispatch({
                type: "CHANNEL_REMOVE",
                id: data._id
            });
        }).catch((error) => {
            console.error(error);
            message.error("Error deleting channel")
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Button
            danger={true}
            type="primary"
            icon={<DeleteOutlined/>}
            onClick={() => deleteChannel(id)}
            loading={loading}
            children="Delete"
        />
    )
}

export default DeleteChannelButton;