import { Button, Input, InputNumber, Space, message, Progress, Switch } from 'antd';
import { useContext, useState } from 'react';
import axios from "axios";
import { AppStateContext, AppStateDispatch } from '../context/AppStateContext';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import { Channel, Oven } from '../context/types';
import Avatar from '../components/Avatar';

function getErrorMessage(error: string){
    switch(error){
        case "CHANNEL_NOT_FOUND":
            return "Channel not found!";
        case "OVEN_EXISTS":
            return "Oven already added!"
        default:
            return "Unknown error!"
    }
}

const AddChannelForm: React.FC = () => {
    const dispatch = useContext(AppStateDispatch);
    const [loading, setLoading] = useState(false);

    const [username, setUsername] = useState("");
    const [size, setSize] = useState(1);

    function addOven(){
        setLoading(true);
        axios.post(`api/oven`, {username, size}).then(({data}) => {
            message.success("Oven added successfuly!")
            dispatch({
                type: "OVEN_ADD",
                oven: data
            })
        }).catch(error => {
            message.error(getErrorMessage(error.response.data.message))
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Space.Compact block size="middle">
            <Input
                disabled={loading}
                autoComplete="off"
                allowClear
                placeholder="username"
                prefix="@"
                onChange={e => setUsername(e.target.value)}
                value={username}
            />
            <InputNumber
                disabled={loading}
                min={1}
                max={100}
                onChange={value => setSize(value || 1)}
                value={size}
            />
            <Button
                onClick={addOven}
                loading={loading}
                // disabled={username.length < 3}
                children="Add oven"
            />
        </Space.Compact>
    )
}

const LinkedChannelsEmpty: React.FC = () => {
    return (
        <div className="linked-channels-empty">
            <span className="placeholder">no linked channels</span>
        </div>
    )
}

const LinkedChannelItem: React.FC<{channel: Channel}> = ({channel}) => {
    return (
        <div className="linked-channel-item">
            <Avatar
                src={channel.info.avatar}
                title={channel.info.title}
                size="small"
            />
            <span 
                className="title"
                children={channel.info.title}
            />
            <span 
                className="id"
                children={channel._id}
            />
        </div>
    )
}

const OvenItem: React.FC<{oven: Oven}> = ({oven}) => {
    const dispatch = useContext(AppStateDispatch);
    const [toggleLoading, setToggleLoading] = useState(false);

    function toggleOven(active: boolean){
        setToggleLoading(true);
        axios.put(`/api/oven/${oven._id}/toggle`, { active }).then(({data}) => {
            dispatch({
                type: "OVEN_EDIT",
                id: oven._id,
                oven: data
            })
            message.success(active ? "Oven turned on" : "Oven turned off");
        }).catch(error => {
            console.log(error);
            message.error("An error occured!");
        }).finally(() => {
            setToggleLoading(false);
        })
    }

    return (
        <div className="oven-item">
            <div className="header">
                <div
                    className="icon-wrapper"
                    style={{backgroundColor: oven.active ? "#e17055" : "#4c4d51"}}
                >
                    <img src="/icon/oven.svg" alt="" className="icon" />
                </div>
                <div className="details">
                    <div className="title">{oven.title}</div>
                    <div className="username">{oven.username}</div>
                </div>
                <div className="switch-wrapper">
                    <Switch
                        loading={toggleLoading}
                        size="small"
                        checked={oven.active}
                        onChange={toggleOven}
                    />
                </div>
                <Progress
                    size="small"
                    type="dashboard"
                    trailColor="rgba(255,255,255,.1)"
                    strokeColor="#e17055"
                    percent={oven.linked_channels.length / oven.size * 100}
                    format={() => 
                        <span className="stat-label">{oven.linked_channels.length} / {oven.size}</span> 
                    } 
                />
            </div>
            <div className="linked-channels">
                {
                    oven.linked_channels.length ?
                        oven.linked_channels.map(channel => 
                            <LinkedChannelItem key={channel._id} channel={channel} />
                        )
                        :
                        <LinkedChannelsEmpty />
                }
            </div>
        </div>
    )
}

const OvenList: React.FC<{ovens: Oven[], isLoaded: boolean}> = ({ovens, isLoaded}) => {
    return isLoaded ?
        <div className="ovens-list">
            {
                ovens.map(oven => 
                    <OvenItem
                        key={oven._id} 
                        oven={oven}  
                    />
                )
                    
            }
        </div>
        :
        <div className='list-loading'>
            <LoadingPlaceholder size={64} color="#20293a" />
        </div>
}

const OvensPage: React.FC<{}> = () => {
    const {isLoaded, ovens} = useContext(AppStateContext);
    return (
        <>
            <h2 className="page-title">Ovens</h2>
            <AddChannelForm />
            <OvenList
                ovens={ovens}
                isLoaded={isLoaded}
            />
        </>
    )
}

export default OvensPage;