import { Button, Input, InputNumber, Popconfirm, Select, Space, message } from "antd";
import axios from "axios";
import { useCallback, useContext, useState } from "react";
import { AppStateContext, AppStateDispatch } from "../context/AppStateContext";
import LoadingPlaceholder from "../components/LoadingPlaceholder";
import {Account, Search, SearchStat, SearchResult} from "../context/types";
import ReactCountryFlag from "react-country-flag";
import { parsePhoneNumber } from "awesome-phonenumber";
import Avatar from "../components/Avatar";
import { DynamicTime } from "../components/DynamicTime";

function formatPhoneNumber(phone: string){
    const parsed = parsePhoneNumber(`+${phone}`);
    return parsed.number?.international
}

function getOptions(accounts: Account[]){
    const no_account = ({
        value: "",
        label: "No account",
        country: null
    });
    const options = accounts.map(account => ({
        value: account._id,
        label: formatPhoneNumber(account.phone),
        country: account.country
    }));
    return [no_account, ...options];
}

const AddSearch: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const dispatch = useContext(AppStateDispatch);
    const { accounts } = useContext(AppStateContext); 

    const [query, setQuery] = useState<string>("");
    const [account, setAccount] = useState("");
    
    function addSearch(){
        setLoading(true);
        axios.post("/api/search", {query, account_id: account}).then(({data}) => {
            message.success("Search created succesfully");
            dispatch({
                type: "SEARCH_ADD",
                search: data
            });
        }).catch(error => {
            console.error(error);
            message.error("Error creating search")
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Space.Compact block size="middle">
            <Input
                placeholder="query"
                autoCorrect="off"
                value={query}
                onChange={e => setQuery(e.target.value)}
            />
            <Select
                disabled={loading}
                value={account}
                onChange={setAccount}
                showSearch
                style={{width: "400px"}}
                optionFilterProp="label"
                children={getOptions(accounts).map(option =>
                    <Select.Option
                        key={`account-option-${option.value}`}
                        value={option.value}
                        label={option.label}
                    >
                        <Space>
                            {option.country ? <ReactCountryFlag countryCode={option.country} /> : <></>}
                            {option.label}
                        </Space>
                    </Select.Option>
                )}
            />
            <Button
                loading={loading}
                onClick={addSearch}
                children="Add search"
            />
        </Space.Compact>
    )
}

const SearchResultItem: React.FC<{result: SearchResult}> = ({result}) => {
    return (
        <div className="search-result-item">
            <Avatar
                src={null}
                title={result.title}
                size="medium"
            />
            <div className="info">
                <span
                    className="title"
                    children={result.title}
                />
                <span
                    className="username"
                    children={`@${result.username}`}
                />
            </div>
            <div className="members">
                <span
                    children={result.members}
                />
                <img
                    className="icon"
                    src="/icon/user-light.svg" 
                    alt="" 
                />
            </div>
        </div>
    )
}

const SearchResults: React.FC<{stat: SearchStat, searchId: string}> = ({stat, searchId}) => {

    return (
        <div className="search-results">
            {
                stat.chats.map(chat => {
                    return <SearchResultItem
                        key={`${searchId}-${chat.chat_id}`}
                        result={chat}
                    />
                }
                )
            }
        </div>
    )
}

const NoSearchResults: React.FC = () => {
    return (
        <div className="search-results">
            <div className="search-results-empty">
                <span className="placeholder">no search results available</span>
            </div>
        </div>
    )
}

const SearchItem: React.FC<{search: Search}> = ({search}) => {
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [refreshLoading, setRefreshLoading] = useState(false);

    const dispatch = useContext(AppStateDispatch);

    const deleteSearch = useCallback((id: string) => {
        setDeleteLoading(true);
        axios.delete(`/api/search/${id}`).then(() => {
            message.success(`Search deleted sucessfully`)
            dispatch({
                type: "SEARCH_REMOVE",
                id: id
            })
        }).catch(error => {
            console.error(error)
            message.error("Error deleting search")
        }).finally(() => {
            setDeleteLoading(false)
        })
    }, []);

    const refreshSearch = useCallback((id: string) => {
        setRefreshLoading(true);
        axios({
            method: "PUT",
            url: `/api/search/${id}`
        }).then(({data}) => {
            message.success("Search updated successfully")
            dispatch({
                type: "SEARCHES_UPDATE",
                update: [data]
            })
        }).catch(error => {
            console.error(error);
            message.error("Error refreshing search");
        }).finally(() => {
            setRefreshLoading(false);
        });
    }, []);

    return (
        <div className="search-item">
            <div className="header">
                <div
                    className="icon-wrapper"
                    style={{backgroundColor: search.linked_account ? "#2e86de" : "#4c4d51"}}
                >
                    <img src="/icon/search-solid.svg" alt="" className="icon" />
                </div>
                <div className="details">
                    <div className="title">
                        {
                            search.linked_account ?
                                <ReactCountryFlag className="flag" countryCode={search.linked_account?.country} />
                                :
                                <></>
                        }
                        
                        <span>{search.query}</span>
                    </div>
                    <div className="last-updated">
                        {
                            search.stat ?
                                <>Last updated <DynamicTime timestamp={search.stat.timestamp} /></> :
                                <>Never updated</>
                        }
                    </div>
                </div>
                <div className="actions">
                    <button
                        onClick={() => refreshSearch(search._id)}
                        disabled={refreshLoading}
                        className="action-button">
                        <img
                            className="icon"
                            src="icon/repeat.svg" 
                            alt="" 
                        />
                    </button>
                    <Popconfirm
                        disabled={deleteLoading}
                        title="Really wanna delete this search?"
                        onConfirm={() => deleteSearch(search._id)}
                        okType="danger"
                        okText="Fuck yea"
                        cancelText="Hell no"
                    >
                        <button
                            className="action-button">
                            <img
                                className="icon"
                                src="icon/trash-can.svg" 
                                alt="" 
                            />
                        </button>
                    </Popconfirm>
                </div>
            </div>
            {
                search.stat ?
                    <SearchResults stat={search.stat} searchId={search._id} /> :
                    <NoSearchResults />
            }
        </div>
    )
}

const SearchList: React.FC = () => {
    const {isLoaded, searches} = useContext(AppStateContext);
    return isLoaded ?
        <div className="searches-list">
            {
                searches.map(search => 
                    <SearchItem
                        key={search._id} 
                        search={search}  
                    />
                )
                    
            }
        </div>
        :
        <div className='list-loading'>
            <LoadingPlaceholder size={64} color="#20293a" />
        </div>
}

const SearchesPage: React.FC<{}> = () => {
    return (
        <>
            <h2 className="page-title">Searches</h2>
            <AddSearch />
            <SearchList />
        </>
    )
}

export default SearchesPage;