import { Button, Input, Modal, Select, Space, message } from 'antd';
import { useContext, useState } from 'react';
import axios from "axios";
import { AppStateContext, AppStateDispatch } from '../context/AppStateContext';
import LoadingPlaceholder from '../components/LoadingPlaceholder';
import Avatar from '../components/Avatar';
import { Channel, Oven } from '../context/types';
import { useNavigate } from 'react-router';
import { OvenSelector } from '../components/OvenSelector';
import { AccountSelector } from '../components/AccountSelector';
import { SearchOutlined } from '@ant-design/icons';

interface ChannelInfo {
    admin: boolean
    avatar: string
    id: number
    members: number
    title: string
    username: string
}

const ChannelPreview: React.FC<{channel: ChannelInfo}> = ({channel}) => {
    return (
        <div className="channel-preview">
            <div className="avatar-wrapper">
                {channel.avatar ?
                    <img
                        className="avatar"
                        src={channel.avatar}
                        alt={channel.title}
                    />
                    :
                    <div className="letter">{channel.title.slice(0,1)}</div>
                }
                
            </div>
            <div className="details">
                <div className="title">{channel.title}</div>
                <div className="username">@{channel.username}</div>
                <div className="members">
                    <img src="/icon/users.svg" className="icon" alt="" />
                    <span>{channel.members.toLocaleString()}</span>
                </div>
            </div>
        </div>
    )
}

const AddChannelForm: React.FC<{children: JSX.Element}> = ({children}) => {
    const dispatch = useContext(AppStateDispatch);
    const {channels, isLoaded} = useContext(AppStateContext);
    const [loading, setLoading] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [channel, setChannel] = useState<ChannelInfo | null>(null);
    const [username, setUsername] = useState("");

    function addChannel(){
        setLoading(true);
        axios.get(`api/channel/${username}/info`).then(({data}) => {
            if(channels.some(channel => channel.chat_id === data.id)) {
                message.warning("Channel already exists")
            } else {
                setChannel(data)
            }
        }).catch(error => {
            if(error.response.data.message === "CHANNEL_NOT_FOUND") {
                message.error("Channel not found")
            } else {
                message.error("Invalid username")
            }
        }).finally(() => {
            setLoading(false);
        })
    }

    function handleAdd(){
        setAddLoading(true);
        axios.post("api/channel", {
            username: username
        }).then(({data}) => {
            setChannel(null);
            message.success("Channel added successfuly!");
            dispatch({
                type: "CHANNEL_ADD",
                channel: data
            })
            setUsername("");
        }).catch(error => {
            message.error("Unable to add channel!");
        }).finally(() => {
            setUsername("");
            setAddLoading(false);
        })
    }

    function handleCancel(){
        setChannel(null);
    }

    return (
        <>
        <Space.Compact block size="middle">
            {children}
            <Input
                disabled={loading || !isLoaded}
                autoComplete="off"
                allowClear
                placeholder="username"
                prefix="@"
                onPressEnter={addChannel}
                onChange={e => setUsername(e.target.value.replace("https://t.me/", ""))}
                value={username}
            />
            <Button
                onClick={addChannel}
                loading={loading}
                children="Add channel"
            />
        </Space.Compact>
        <Modal
            title="Add channel" 
            open={!!channel}
            okButtonProps={{
                disabled: !(channel && channel.admin),
                loading: addLoading
            }}
            okType="primary"
            okText="Add channel"
            onOk={handleAdd}
            onCancel={handleCancel}
        >
            {channel ? <ChannelPreview channel={channel} /> : <></>}
        </Modal>
        </>
    )
}


const ChannelItem: React.FC<{channel: Channel, ovens: Oven[]}> = ({channel}) => {
    const navigate = useNavigate();

    return (
        <div className="channel-item">
            <div className="header">
                <Avatar 
                    src={channel.info.avatar}
                    title={channel.info.title}
                    onClick={() => navigate(`/channel/${channel._id}`)}
                />
                <div className="details">
                    <div className="title">
                        <span>{channel.info.title}</span>
                        {
                            channel.protected ?
                                channel.content_protected ?
                                    <img src="/icon/lock.svg" className="icon" />
                                    :
                                    <img src="/icon/shield.svg" className="icon" />
                                :
                                <></>
                        }
                        {
                            channel.rotation_enabled ?
                                <img src="/icon/rotation.svg" className='icon' />
                                :
                                <></>
                        }
                    </div>
                    <div className="username">{channel.info.username ? `@${channel.info.username}` : "private"} – {channel.info.members}</div>
                </div>
            </div>
            <div className="item-selection">
                <OvenSelector
                    channel_id={channel._id}
                    linked_oven={channel.linked_oven?._id}
                />
            </div>
            <div className="item-selection">
                <AccountSelector
                    channel_id={channel._id}
                    linked_account={channel.linked_account?._id}
                />
            </div>
        </div>
    )
}

const ChannelList: React.FC<{channels: Channel[]}> = ({channels}) => {
    const {isLoaded, ovens} = useContext(AppStateContext);

    return isLoaded ?
        <>
            <div className="channels-list">
                {
                    channels.map(channel => (
                        <ChannelItem 
                            key={channel._id} 
                            channel={channel} 
                            ovens={ovens}
                        />
                    ))
                }
            </div>
        </>
        :
        <div className='list-loading'>
            <LoadingPlaceholder size={64} color="#20293a" />
        </div>   
}

const ChannelsPage: React.FC<{}> = () => {
    const [search, setSearch] = useState("");
    const { channels } = useContext(AppStateContext);
    
    return (
        <>
            <h2 className="page-title">Channels</h2>
            <AddChannelForm>
                <Input
                    prefix={<SearchOutlined />}
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                />
            </AddChannelForm>
            <ChannelList 
                channels={channels.filter(channel => {
                    return (
                        (channel.info.username || "").toLowerCase().includes(search) ||
                        channel.info.title.toLowerCase().includes(search) ||
                        channel.linked_account?.phone.includes(search)
                    )
                })} 
            />
        </>
    )
}

export default ChannelsPage;