import { NavLink } from "react-router-dom";

const navigation = [
    {
        title: "Channels",
        to: "/channels",
        icon: "/icon/bullhorn.svg"
    },
    {
        title: "Ovens",
        to: "/ovens",
        icon: "/icon/oven.svg"
    },
    {
        title: "Accounts",
        to: "/accounts",
        icon: "/icon/user.svg"
    },
    {
        title: "Proxies",
        to: "/proxies",
        icon: "/icon/globe.svg"
    },
    {
        title: "Searches",
        to: "/searches",
        icon: "/icon/search.svg"
    }
]

interface NavLinkOpts {
    title: string
    to: string
    icon: string
}

const Link: React.FC<NavLinkOpts> = ({title, to, icon}) => {
    return (
        <NavLink to={to} className={({isActive}) => isActive ? "nav-link active" : "nav-link"}>
            <img src={icon} alt="" className="icon" />
            <span className="title">{title}</span>
        </NavLink>
    )
}

const Navigation: React.FC<{menu: NavLinkOpts[]}> = ({menu}) => {
    return (
        <div className="navigation">
            {
                menu.map(item => <Link key={item.to} {...item} />)
            }
        </div>
    )
}

export const Header: React.FC<{}> = () => {
    return (
        <header className="site-header">
            <div className="container">
                <div className="logo-wrapper">
                    <img src="/icon/cannabis.svg" alt="" className="logo" />
                </div>
                <Navigation menu={navigation} />
            </div>
        </header>
    )
}