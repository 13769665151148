import { Select, Space } from "antd"
import { Proxy } from "../context/types";
import ReactCountryFlag from "react-country-flag";

function getOptions(proxies: Proxy[]){
    const no_proxy = ({
        value: "",
        label: "No proxy",
        country: null
    });
    const options = proxies.map(proxy => ({
        value: proxy._id,
        label: proxy.host,
        country: proxy.country
    }));
    return [no_proxy, ...options];
}

const ProxySelector: React.FC<{
    proxies: Proxy[]
    disabled: boolean
    value: string
    onChange: (proxy: string) => void
}> = (opts) => {
    return (
        <Select
            disabled={opts.disabled}
            value={opts.value}
            onChange={opts.onChange}
            showSearch
            style={{width: "400px"}}
            optionFilterProp="label"
            children={getOptions(opts.proxies).map(option =>
                <Select.Option
                    key={`proxy-option-${option.value}`}
                    value={option.value}
                    label={option.label}
                >
                    <Space>
                        {option.country ? <ReactCountryFlag countryCode={option.country} /> : <></>}
                        {option.label}
                    </Space>
                </Select.Option>
            )}
        />
    )
}

export default ProxySelector;