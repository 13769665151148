import { message, Button } from "antd";
import axios from "axios";
import { useContext, useState } from "react";
import { AppStateDispatch } from "../context/AppStateContext";

const SyncChannelButton: React.FC<{id: string}> = ({id}) => {
    const dispatch = useContext(AppStateDispatch);
    const [loading, setLoading] = useState(false);

    function syncChannel(){
        setLoading(true)
        axios.put(`/api/channel/${id}`).then(({data}) => {
            message.success("Channel synced successfully")
            dispatch({
                type: "CHANNEL_EDIT",
                id: id,
                channel: data
            })
        }).catch(error => {
            console.error(error);
            message.error("Error syncing channel")
        }).finally(() => {
            setLoading(false);
        })
    }

    return (
        <Button
            loading={loading}
            onClick={syncChannel}
            children="Sync"
        />
    )
}

export default SyncChannelButton;